<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑角色' : '新增角色'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import roleService from '@/api/service/role.js';

  export default {
    name: 'addRoleDialog',
    data() {
      return {
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '角色名',
              prop: 'roleName',
              rules: [this.$formRules.required('请输入角色名')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '备注',
              prop: 'remark',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          roleService.getRoleDetail({ id }).then((res) => {
            this.formData = res;
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            const params = {
              id: this.formData.id,
              roleName: this.formData.roleName,
              remark: this.formData.remark
            };
            return roleService[this.isEdit ? 'editRole' : 'addRole'](params);
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.isEdit = false;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
