<template>
  <dog-dialog
    ref="dogDialog"
    width="900px"
    title="配置用户"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <div class="flex_row dialog_zone_scroll setUsersDialog">
      <div class="flex_1 flex_column">
        <dog-search
          ref="search"
          :config="searchConfig"
          @search="search"
        ></dog-search>
        <dog-table
          ref="dogTable"
          id="addc3862-42fe-494c-ae5b-2a3698ef812d"
          row-key="id"
          :columns="tableColumns"
          :service="getList"
          :column-type="columnType"
          @selection-change="selectedData = $event"
        >
        </dog-table>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="setUsersDialog__selected">
        <h4>已选：</h4>
        <dog-scroll-box class="setUsersDialog__selected__tags">
          <el-tag
            size="small"
            closable
            @close="removeUser(user)"
            v-for="user in selectedData"
            :key="user.id"
            >{{ user.userName }}</el-tag
          >
        </dog-scroll-box>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import roleService from '@/api/service/role.js';
  import userService from '@/api/service/user';

  export default {
    name: 'setUsersDialog',
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '用户名',
            key: 'userName'
          },
          {
            type: 'input',
            label: '真实姓名',
            key: 'realName'
          }
        ],
        tableColumns: [
          { prop: 'userName', label: '用户名', width: 90 },
          { prop: 'realName', label: '真实姓名', width: 90 },
          { prop: 'sex_cn', label: '性别', width: 70 },
          {
            prop: 'avatar',
            label: '头像',
            width: 80,
            render: (h, { props: { row } }) => {
              return row.avatar ? (
                <el-avatar src={row.avatar} size={20}></el-avatar>
              ) : (
                <el-avatar icon='el-icon-user-solid' size={20}></el-avatar>
              );
            }
          },
          { prop: 'remark', label: '个人简介', width: 200 }
        ],
        columnType: [
          {
            type: 'selection',
            props: {
              reserveSelection: true
            }
          }
        ],
        selectedData: [],
        roleId: null
      };
    },
    methods: {
      openFrame(roleId) {
        this.roleId = roleId;
        this.$refs.dogDialog.open();
        roleService.getRoleUsers({ roleId }).then((res) => {
          for (let i = 0; i < res.length; i++) {
            this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
              res[i],
              true
            );
          }
          this.searchData();
        });
      },
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return userService.getUserList(params).then((res) => {
          res.list.forEach((item) => {
            for (let i = 0; i < this.selectedData.length; i++) {
              // 所选数据重新赋值为列表数据，否则选中效果渲染不出来
              if (this.selectedData[i].id === item.id) {
                this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                  this.selectedData[i],
                  false
                );
                this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                  item,
                  true
                );
                break;
              }
            }
          });
          return res;
        });
      },
      removeUser(user) {
        this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
          user,
          false
        );
      },
      confirm(done) {
        roleService
          .setRoleUsers({
            roleId: this.roleId,
            userIds: this.selectedData.map((item) => item.id)
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.selectedData = [];
        this.roleId = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(setUsersDialog) {
    @include e(selected) {
      width: 150px;
      $h4_margin: 10px;
      $h4_height: 16px;
      h4 {
        margin-top: $h4_margin;
        margin-bottom: $h4_margin;
        line-height: $h4_height;
      }
      @include e(tags) {
        height: calc(100% - #{$h4_height} - #{$h4_margin} - #{$h4_margin});
      }
      /deep/ .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
</style>
