<template>
  <div class="flex_column roleManage">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="c7cb177e-6bf6-4df7-8c61-2e48a38609e9"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-role-dialog
      ref="addRoleDialog"
      @confirm="searchData"
    ></add-role-dialog>
    <set-users-dialog
      ref="setUsersDialog"
      @confirm="searchData"
    ></set-users-dialog>
    <set-resources-dialog
      ref="setResourcesDialog"
      @confirm="searchData"
    ></set-resources-dialog>
  </div>
</template>

<script>
  import roleService from '@/api/service/role';
  import addRoleDialog from './addRoleDialog.vue';
  import setUsersDialog from './setUsersDialog.vue';
  import setResourcesDialog from './setResourcesDialog.vue';

  export default {
    name: 'roleManage',
    components: { addRoleDialog, setUsersDialog, setResourcesDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '角色名',
            key: 'roleName'
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 210,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editRole',
                    callback: () => this.$refs.addRoleDialog.openFrame(row.id)
                  },
                  {
                    code: 'setResources',
                    callback: () =>
                      this.$refs.setResourcesDialog.openFrame(row.id)
                  },
                  {
                    code: 'setUsers',
                    callback: () => this.$refs.setUsersDialog.openFrame(row.id)
                  }
                ]}></permission-element>
            )
          },
          { prop: 'roleName', label: '角色名', width: 100 },
          { prop: 'remark', label: '备注', width: 120 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addRole',
            callback: () => this.$refs.addRoleDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteRoles',
            callback: this.deleteRoles
          }
          // {
          //   buttonType: 'primary',
          //   name: '导出角色',
          //   callback: this.exportRoles
          // },
          // {
          //   buttonType: 'primary',
          //   name: '导入角色',
          //   callback: this.importRoles
          // }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return roleService.getRoleList(params);
      },
      deleteRoles() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          roleService
            .deleteRoles({ ids: this.selectedData.map((item) => item.id) })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
